



















import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: { ValidationProvider },
})
export default class ValidationTextField extends Vue {
  @Prop([Object, String])
  rules!: any | string;
  @Prop()
  value!: any;

  @Prop()
  vid!: string;
  @Emit()
  private input(val: string) {
    return val;
  }

  private innerValue = "";

  @Watch("innerValue")
  onChangeInnerValue(newVal: string) {
    this.input(newVal);
  }

  @Watch("value")
  onChangeValue(newVal: string) {
    this.innerValue = newVal;
  }

  protected created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
