









































import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class BaseForm extends Vue {
  @Prop({ type: String })
  max_width!: string;

  @Prop({ type: String, default: "타이틀" })
  title!: string;

  @Prop()
  isConnecting!: boolean;

  @Emit()
  private cancel() {
    return null;
  }

  @Emit()
  private submit() {
    return null;
  }

  private visible = true;

  protected created() {
    //
  }
}
